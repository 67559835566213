import React, { Component } from 'react';
import './equalizer.css';

// Changing Variables
let ctx;

// constants
//const width = window.innerWidth / 2;
//const height = window.innerHeight / 2;
const bars = 21;
const ncomponents = 15;

class EqualizerHtml5 extends Component {

    timeout = null;

    constructor(props){
        super(props);
        this.state = {
            classes: [ 'col col_0 select__1', 'col col_1 select__1', 'col col_2 select__1',
                       'col col_4 select__1', 'col col_4 select__1', 'col col_6 select__1',
                       'col col_7 select__1'
                     ],
            nbars: props.nbars || 8,
        }
        this.canvas = React.createRef();
        this.analyzer = null;
        this.interval = null;
    }
    
    componentDidMount() {
        const that = this;
        this.interval = setInterval(() => {
            if (that.canvas){
                that.renderBars(that.canvas.current);
            }
        }, 100);
        
    }

    componentWillUnmount() {
        if (this.interval) clearInterval(this.interval);
    }

    renderBars(canvas) {
        const { active } = this.props;
        if (!canvas) return;
        const s = getComputedStyle(canvas);
        const width = s.width.split('px')[0];
        const height = s.height.split('px')[0];

        canvas.width = width;
        canvas.height = height;

        ctx = canvas.getContext("2d");
        let barHeight = 1;
        const componentH = 2*(height)/(2*ncomponents+ncomponents-1);
        let barWidth = 2*(width)/(2*bars+bars-1);
        let x = 0;

        ctx.fillStyle = '#FF901E';
        for (let i = 0; i < bars; i++) {
            let y = componentH;
            if (active)
                barHeight = Math.ceil(Math.random()*ncomponents);
            for(let j = 1; j <= barHeight; j++) {
                ctx.fillRect(x,height-y,barWidth,componentH);
                y += componentH*(3/2);
            }
            x += barWidth*(3/2);
        }
        x = barWidth;
        barHeight = componentH;
        ctx.fillStyle = '#d1d1d1';
        for (let i = 0; i < (bars-1); i++) {
            if (active) 
                barHeight = Math.ceil(Math.random()*height);

            ctx.fillRect(x,height-barHeight,(barWidth/2),barHeight);

            x += barWidth*(3/2);
        }
    }

    render () {
        return  (
            <canvas id="equalizer_canvas" ref={this.canvas}>
                {this.props.children}
            </canvas>
        );
    }
}

export default EqualizerHtml5;