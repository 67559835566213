import React, { Component } from 'react';
import './equalizer.css';

class Equalizer extends Component {

    timeout = null;

    constructor(props){
        super(props);
        this.state = {
            classes: [ 'col col_0 select__1', 'col col_1 select__1', 'col col_2 select__1',
                       'col col_4 select__1', 'col col_4 select__1', 'col col_6 select__1',
                       'col col_7 select__1'
                     ],
            nbars: props.nbars || 8,
        }
    }
    
    componentDidMount() {
        const { nbars } = this.state;
        const that = this;
        this.timeout = setInterval(function(){
                let classesn = [];
                for(let i = 1; i <= nbars; i++){
                    if (that.props.active) {
                        let randomBars = Math.ceil(Math.random()*12);
                        classesn = [...classesn, 'col col_'+(9-i)+' select__'+randomBars ];
                    } else 
                        classesn = [...classesn, 'col col_'+(9-i)+' select__1' ];
                }
                that.setState({ classes: classesn })
            }, 50);
    }

    componentWillUnmount() {
        if (this.timeout) clearInterval(this.timeout);
    }

    renderBars = () => {
        const { classes } = this.state;
        return (
            <g className="equalizer">
            <g className={classes[0]}>
            <path className="rect__1" d="M196 110h24v6h-24z"/>
            <path className="rect__2" d="M196 100h24v6h-24z"/>
            <path className="rect__3" d="M196 90h24v6h-24z"/>
            <path className="rect__4" d="M196 80h24v6h-24z"/>
            <path className="rect__5" d="M196 70h24v6h-24z"/>
            <path className="rect__6" d="M196 60h24v6h-24z"/>
            <path className="rect__7" d="M196 50h24v6h-24z"/>
            <path className="rect__8" d="M196 40h24v6h-24z"/>
            <path className="rect__9" d="M196 30h24v6h-24z"/>
            <path className="rect__10" d="M196 20h24v6h-24z"/>
            <path className="rect__11" d="M196 10h24v6h-24z"/>
            <path className="rect__12" d="M196 0h24v6h-24z"/>
          </g>
            <g className={classes[1]}>
              <path className="rect__1" d="M168 110h24v6h-24z"/>
              <path className="rect__2" d="M168 100h24v6h-24z"/>
              <path className="rect__3" d="M168 90h24v6h-24z"/>
              <path className="rect__4" d="M168 80h24v6h-24z"/>
              <path className="rect__5" d="M168 70h24v6h-24z"/>
              <path className="rect__6" d="M168 60h24v6h-24z"/>
              <path className="rect__7" d="M168 50h24v6h-24z"/>
              <path className="rect__8" d="M168 40h24v6h-24z"/>
              <path className="rect__9" d="M168 30h24v6h-24z"/>
              <path className="rect__10" d="M168 20h24v6h-24z"/>
              <path className="rect__11" d="M168 10h24v6h-24z"/>
              <path className="rect__12" d="M168 0h24v6h-24z"/>
            </g>
            <g className={classes[2]}>
              <path className="rect__1" d="M140 110h24v6h-24z"/>
              <path className="rect__2" d="M140 100h24v6h-24z"/>
              <path className="rect__3" d="M140 90h24v6h-24z"/>
              <path className="rect__4" d="M140 80h24v6h-24z"/>
              <path className="rect__5" d="M140 70h24v6h-24z"/>
              <path className="rect__6" d="M140 60h24v6h-24z"/>
              <path className="rect__7" d="M140 50h24v6h-24z"/>
              <path className="rect__8" d="M140 40h24v6h-24z"/>
              <path className="rect__9" d="M140 30h24v6h-24z"/>
              <path className="rect__10" d="M140 20h24v6h-24z"/>
              <path className="rect__11" d="M140 10h24v6h-24z"/>
              <path className="rect__12" d="M140 0h24v6h-24z"/>
            </g>
            <g className={classes[3]}>
              <path className="rect__1" d="M112 110h24v6h-24z"/>
              <path className="rect__2" d="M112 100h24v6h-24z"/>
              <path className="rect__3" d="M112 90h24v6h-24z"/>
              <path className="rect__4" d="M112 80h24v6h-24z"/>
              <path className="rect__5" d="M112 70h24v6h-24z"/>
              <path className="rect__6" d="M112 60h24v6h-24z"/>
              <path className="rect__7" d="M112 50h24v6h-24z"/>
              <path className="rect__8" d="M112 40h24v6h-24z"/>
              <path className="rect__9" d="M112 30h24v6h-24z"/>
              <path className="rect__10" d="M112 20h24v6h-24z"/>
              <path className="rect__11" d="M112 10h24v6h-24z"/>
              <path className="rect__12" d="M112 0h24v6h-24z"/>
            </g>
            <g className={classes[4]}>
              <path className="rect__1" d="M84 110h24v6H84z"/>
              <path className="rect__2" d="M84 100h24v6H84z"/>
              <path className="rect__3" d="M84 90h24v6H84z"/>
              <path className="rect__4" d="M84 80h24v6H84z"/>
              <path className="rect__5" d="M84 70h24v6H84z"/>
              <path className="rect__6" d="M84 60h24v6H84z"/>
              <path className="rect__7" d="M84 50h24v6H84z"/>
              <path className="rect__8" d="M84 40h24v6H84z"/>
              <path className="rect__9" d="M84 30h24v6H84z"/>
              <path className="rect__10" d="M84 20h24v6H84z"/>
              <path className="rect__11" d="M84 10h24v6H84z"/>
              <path className="rect__12" d="M84 0h24v6H84z"/>
            </g>
            <g className={classes[5]}>
              <path className="rect__1" d="M56 110h24v6H56z"/>
              <path className="rect__2" d="M56 100h24v6H56z"/>
              <path className="rect__3" d="M56 90h24v6H56z"/>
              <path className="rect__4" d="M56 80h24v6H56z"/>
              <path className="rect__5" d="M56 70h24v6H56z"/>
              <path className="rect__6" d="M56 60h24v6H56z"/>
              <path className="rect__7" d="M56 50h24v6H56z"/>
              <path className="rect__8" d="M56 40h24v6H56z"/>
              <path className="rect__9" d="M56 30h24v6H56z"/>
              <path className="rect__10" d="M56 20h24v6H56z"/>
              <path className="rect__11" d="M56 10h24v6H56z"/>
              <path className="rect__12" d="M56 0h24v6H56z"/>
            </g>
            <g className={classes[6]}>
              <path className="rect__1" d="M28 110h24v6H28z"/>
              <path className="rect__2" d="M28 100h24v6H28z"/>
              <path className="rect__3" d="M28 90h24v6H28z"/>
              <path className="rect__4" d="M28 80h24v6H28z"/>
              <path className="rect__5" d="M28 70h24v6H28z"/>
              <path className="rect__6" d="M28 60h24v6H28z"/>
              <path className="rect__7" d="M28 50h24v6H28z"/>
              <path className="rect__8" d="M28 40h24v6H28z"/>
              <path className="rect__9" d="M28 30h24v6H28z"/>
              <path className="rect__10" d="M28 20h24v6H28z"/>
              <path className="rect__11" d="M28 10h24v6H28z"/>
              <path className="rect__12" d="M28 0h24v6H28z"/>
            </g>
            <g className={classes[7]}>
            <path className="rect__1" d="M0 110h24v6H0z"/>
            <path className="rect__2" d="M0 100h24v6H0z"/>
            <path className="rect__3" d="M0 90h24v6H0z"/>
            <path className="rect__4" d="M0 80h24v6H0z"/>
            <path className="rect__5" d="M0 70h24v6H0z"/>
            <path className="rect__6" d="M0 60h24v6H0z"/>
            <path className="rect__7" d="M0 50h24v6H0z"/>
            <path className="rect__8" d="M0 40h24v6H0z"/>
            <path className="rect__9" d="M0 30h24v6H0z"/>
            <path className="rect__10" d="M0 20h24v6H0z"/>
            <path className="rect__11" d="M0 10h24v6H0z"/>
            <path className="rect__12" d="M0 0h24v6H0z"/>
          </g>
          </g>
        );
    }

    render () {
    return  (
        <svg xmlns="http://www.w3.org/2000/svg" width="220" height="116" viewBox="0 0 220 116">
        <title>
            equalizer
        </title>

            {this.renderBars()}
        </svg>
    );
    }
}

export default Equalizer;