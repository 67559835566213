import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

const RedirectIfAuthRouteW = ({ component: Component, session, redirect_to , ...rest }) => {
    return (
    <Route
      {...rest}
      render={props =>
  
        session.isAuthenticated === false ? <Component {...props} /> : <Redirect to={redirect_to} />
      }
    />
    )
}

const mapStateToProps = (state) => {
  return {
      session: state.session,
  }
}

const RedirectIfAuthRoute = connect(mapStateToProps,null)(RedirectIfAuthRouteW);

export { RedirectIfAuthRoute };