import { combineReducers } from 'redux';
import ApiRequestReducer from './apiRequestReducer';
import SessionReducer from './sessionReducer';

import { USER_SIGNOUT } from '../constants/session';

const appReducer = combineReducers({
    ApiRequestReducer,
    session: SessionReducer,
});

export default (state,action) => {
    if (action.type === USER_SIGNOUT) {
      state = undefined
    }
  return appReducer(state, action);
};
