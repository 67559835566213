import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import Progress from 'react-progress-2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-progress-2/main.css';
import './login.css';

import { setSessionAuth } from '../../redux/actions';

class Login extends Component {
    
    state = {
        email: '',
        password: '',
        loading: false,
    }

    componentDidMount() {
        document.title = 'Login | Circuito Líder';
        console.log(this.props.history);
    }

    validateForm = (e) => {
        e.preventDefault();
        const { email, password } = this.state;
        let error = false;
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(String(email).toLowerCase())) {
          toast.error('Ingrese un email valido', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000
          });
          error = true;
        }
        if (password.trim() === '' ) {
          toast.error('Contraseña es requerido', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000
          });
          error = true;
        }
    
        if (error) return;
        this.handleFormSubmit();
    }

    handleFormSubmit = () => {
        const { password, email } = this.state;
        this.setState({ loading: true });
        Progress.show();
        axios({
          method: "post",
          url: `${process.env.REACT_APP_API_BASE}/login`,
          headers: { "content-type": "application/json", "Access-Control-Allow-Origin": "*" },
          data: { password, email }
        })
          .then(result => {
            if (result.data.status === 'success') {
              this.setState({ 
                                email: '',
                                password: '',

                            });
              localStorage.setItem('auth_user',JSON.stringify({ email: email, access_token: result.data.access_token }));
              this.props.setSessionAuth({ isAuthenticated: true, email: email, access_token: result.data.access_token });
              this.props.history.replace('/admin');
            } else {
              toast.error('Credenciales invalidas', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
              });
              this.setState({ password: '', loading: false });
            }
          })
          .catch(error => {
            this.setState({ error: error.message, loading: false });
            toast.error('No se pudo enviar, intente nuevamente', {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000
            });
          })
          .finally(() => { Progress.hide(); });
    };


    render() {
        return (
            <React.Fragment>
                <header className="header">
                    <div className="container-fluid">
                        <nav className="navbar navbar-expand-lg navbar-light">
                                <ul className="navbar-nav mx-auto">
                                    <NavLink to="/"><img src="/img/CIRCUITO-LIDER.png" alt="" className="img-fluid nav-logo" /></NavLink>
                                </ul>
                        </nav>
                    </div>
                </header>
                <ToastContainer autoClose={false} limit={4} />
                <section className="text-center">
                <div className="card card-login">
                    <div className="card-body">
                        <div className="section-title text-center">
                            <h2 className="contact-title">Iniciar Sesión</h2>
                        </div>
                        <form className="contact-form">
                            <div className="row">
                                <div className="col col-xs-12">
                                    <input type="email" placeholder="Email" value={this.state.email} onChange={(e) => { this.setState({ email: e.target.value }) }}  />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col col-xs-12">
                                    <input type="password" placeholder="Contraseña" value={this.state.password} onChange={(e) => { this.setState({ password: e.target.value }) }}  />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col col-xs-12">
                                    <button type="submit" className="btn theme-btn" disabled={this.state.loading} onClick={this.validateForm.bind(this)}>Enviar</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                </section>
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = {
    setSessionAuth
};

export default withRouter(connect(null,mapDispatchToProps)(Login));