import {
    SESSION_TOKEN_SET,
    SESSION_CLEAN,
    SESSION_ALL_SET,
    SESSION_FIELD_SET,
} from '../constants/session'

const INITIAL_STATE = {
    access_token: '',
    isAuthenticated: false,
    email: '',
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SESSION_FIELD_SET:
          return {
            ...state,
            [action.payload.prop]: action.payload.value
          }
        case SESSION_TOKEN_SET:
            return {
                ...state,
                access_token: action.payload.access_token,
                isAuthenticated: action.payload.isAuthenticated,
                email: action.payload.email,
            }
        case SESSION_ALL_SET:
            return {
                ...state,
                access_token: action.payload.access_token,
                isAuthenticated: action.payload.isAuthenticated,
                email: action.payload.email,
            }
        case SESSION_CLEAN:
          return INITIAL_STATE;
        default:
            return {
                ...state
            }
    }
};
