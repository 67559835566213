import {
        SESSION_ALL_SET,
} from '../constants/session';

export const setSessionAuth = (user_data) => {
    return {
            type:SESSION_ALL_SET,
            payload: user_data,
    };
};

export const logoutSession = () => {
  return async (dispatch) => {
    localStorage.clear();
    window.location.replace('/');
  }
};