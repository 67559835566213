import { API_REQUEST_STARTED, API_REQUEST_SUCCEEDED,
        API_REQUEST_FAILED, API_REQUEST_CANCEL_REQUEST,
      } from '../constants/backend';

import request from '../api/request';
import { logoutSession } from './sessionActions';


export const requestStarted = (id, cancelTokenSource) => ({
  type: API_REQUEST_STARTED,
  payload: { id, cancelTokenSource }
});

export const requestSucceeded = id => ({
  type: API_REQUEST_SUCCEEDED,
  payload: { id }
});

export const requestFailed = (id, error) => ({
  type: API_REQUEST_FAILED,
  payload: { id, error }
});

export const cancelRequest = id => ({
  type: API_REQUEST_CANCEL_REQUEST,
  payload: { id }
});

export const cancelRequestThunk = id => async (
    dispatch,
    getState
  ) => {
    const { apiRequests } = getState();
    if (apiRequests) {
      const apiRequest = apiRequests.apiRequests[id];
      if (apiRequest && apiRequest.loading) {
        apiRequest.cancelTokenSource.cancel('cancelled');
      }
    }
    dispatch(cancelRequest(id));
};

export const createRequest = (
  { requestId, requestParams },
  needsAuthentication = false
) => async (dispatch, getState) => {
  const cancelTokenSource = request.getCancelTokenSource();
  if (needsAuthentication) {
    const { session } = getState();
    request.setBearerToken(session.access_token);
  }

  dispatch(requestStarted(requestId, cancelTokenSource));
  try {
    const res = await request.send({
      ...requestParams,
      cancelToken: cancelTokenSource.token
    });
    dispatch(requestSucceeded(requestId));
    return res;
  } catch (error) {
    if (error.message === 'canceled') {
      throw error;
    }

    if (error.response && error.response.status === 401) {
      if (
        needsAuthentication
      ) {
        await dispatch(logoutSession());
        throw error;
      }
    }

    dispatch(requestFailed(requestId, error));
    throw error;
  }
};
