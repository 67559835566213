import { API_REQUEST_STARTED, API_REQUEST_SUCCEEDED,
        API_REQUEST_FAILED, API_REQUEST_CANCEL_REQUEST
      } from '../constants/backend'

export default (state = {}, action) => {
  switch (action.type) {
    case API_REQUEST_STARTED:
      return {
        ...state,
        [action.payload.id]: {
          loading: true,
          cancelTokenSource: action.payload.cancelTokenSource
        }
      };
    case API_REQUEST_SUCCEEDED:
      return {
        ...state,
        [action.payload.id]: {
          loading: false,
          cancelTokenSource: null
        }
      };
    case API_REQUEST_FAILED:
      return {
        ...state,
        [action.payload.id]: {
          loading: false,
          cancelTokenSource: null,
          error: action.payload.error
        }
      };
    case API_REQUEST_CANCEL_REQUEST: {
      return {
        ...state.byId,
        [action.payload.id]: {
          loading: false,
          cancelled: true,
          cancelTokenSource: null
        }
      };
    }
    default:
      return state;
  }
}
